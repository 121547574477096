// PRIMARY COLOR
$primary: #e71d2f;
$primary-light: #ffab40ff;
$primary-lighter: #db4c58;
$primary-darkest: #ffab40ff;

// ALERT
$alert-secondary: #21bf73;

// WARNING
$rose: #dd2c00;
$rose-light: #ffe4dd;

// VARIANT
$mist: #bdbdbd;
$misty-light: #eff0f2;
$misty-darkest: #868686;

// Z-INDEX
$navbar-z-index: 10;
$sidebar-z-index: 11;
$modal-bg-z-index: 50;
